import React, { FC } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { graphql } from 'gatsby';
import classNames from 'classnames';
import useScreenRecognition from 'hooks/useScreenRecognition';

import { DangerouslySetInnerHtml } from 'layout';
import { VisualType, VisualVariant } from 'common/Button/enums';
import Image from 'common/Image';
import LinkButton from 'components/LinkButton';

import { VictoriesBannerProps } from './models';

import './VictoriesBanner.scss';

const VictoriesBanner: FC<VictoriesBannerProps> = ({
  data: {
    description,
    titleWithParts,
    additionalInfo,
    backgroundImage,
    tabletBackgroundImage,
    backgroundImageAlt,
    backgroundImageGif,
    ctaButton,
    ctaButtonAriaLabel,
  },
}) => {
  const { isSmallTablet } = useScreenRecognition();

  return (
    <Row
      data-testid="victories-banner"
      className={classNames('victories-banner', {
        'victories-banner--gif-image': backgroundImageGif?.url,
      })}
    >
      <div className="victories-banner__left-section">
        <Col className="victories-banner__information">
          <DangerouslySetInnerHtml
            html={titleWithParts}
            className="victories-banner__title-with-parts"
          />

          {description ? (
            <DangerouslySetInnerHtml html={description} className="victories-banner__description" />
          ) : null}

          {ctaButton?.length ? (
            <LinkButton
              text={ctaButton[0].name}
              type={VisualType.VanishVictories}
              url={ctaButton[0].url}
              ariaLabel={ctaButtonAriaLabel}
              variant={VisualVariant.Light}
            />
          ) : null}
        </Col>
      </div>

      {backgroundImage?.imagePicker?.childImageSharp ? (
        <Image
          imageData={backgroundImage?.imagePicker}
          alt={backgroundImageAlt}
          objectFit="contain"
          className="banner-image-component"
        />
      ) : null}

      {backgroundImageGif?.url && !isSmallTablet ? (
        <img
          src={backgroundImageGif.url}
          alt={backgroundImageAlt}
          className="banner-image-component"
        />
      ) : null}

      {tabletBackgroundImage?.imagePicker && isSmallTablet ? (
        <Image
          imageData={tabletBackgroundImage?.imagePicker}
          alt={backgroundImageAlt}
          objectFit="contain"
          className="banner-image-component"
        />
      ) : null}

      <div className="victories-banner__additional-info-wrapper">
        <Col>
          <DangerouslySetInnerHtml
            html={additionalInfo}
            className="victories-banner__additional-info"
          />
        </Col>
      </div>
    </Row>
  );
};

export const query = graphql`
  fragment VictoriesBannerFragment on VictoriesBannerType {
    description
    titleWithParts
    additionalInfo
    backgroundImageGif {
      url
    }
    backgroundImage {
      imagePicker {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
        }
      }
    }
    tabletBackgroundImage {
      imagePicker {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED, quality: 100)
        }
      }
    }
    backgroundImageAlt
    ctaButton {
      url
      name
    }
    ctaButtonAriaLabel
  }
`;

export default VictoriesBanner;
